import React, { useEffect, useRef, useState } from "react";
import filter_icon from "../../assets/images/filter_icon.svg";
import reload_icon from "../../assets/images/reload_icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import videoImg from "../../assets/images/video_thum.png";
import { FaPlay } from "react-icons/fa6";
import { LiaTimesSolid } from "react-icons/lia";
import { IoClose, IoCloseCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-default/dist/all.css";
import Api from "../Api/Api";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { eachYearOfInterval, getYear, getMonth } from "date-fns";
import Datepickercomponent from "../util/Datepicker";
import VideoDuration from "../util/VideoDuration";
import SkeletonLoader from "../util/Skeleton-previous";

function PreviousSessionMeetings() {
  //custom datepicker
  const [startDate, setStartDate] = useState(new Date());
  const [Loader,  setLoader] = useState(true)
  const years = eachYearOfInterval({
    start: new Date(1990, 0, 1),
    end: new Date(),
  });
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const moment = require("moment");
  const [isOpen, setIsOpen] = useState(false);
  const [previousVideoData, setPreviousVideoData] = useState([]);

  const { PreviousMeeting } = Api();

  const toggleVisibilityMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectfromdate, setSelectedDate] = useState(null);
  const [selecttodate, setselectTodate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [fromtimevalue, setFromtimevalue] = useState(null);
  const [totimevalue, setTotimevalue] = useState(null);
  const defaultTime = new Date();
  defaultTime.setHours(0);
  defaultTime.setMinutes(0);

  // const PreviousVideo = async () => {
  //     try {
  //         let data = await PreviousMeeting();
  //         setPreviousVideoData(data.parameters.data);
  //         console.log("data.parameters", data.parameters.data);
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };

  const handleDateChange = (date) => {
    console.log(date);
    if (date) {
      // setSelectedDate(null);
      // const formatdate = moment(date).format("DD/MM/YY");
      setSelectedDate(date);
      setCurrentPage(0);
      handleFilterChange({
        status: selectedStatus,
        fromTime: fromTime,
        toTime: toTime,
        selectfromdate: date,
        selecttodate: selecttodate,
        // currentPage: currentPage,
      });
    }
  };

  const handleTodate = (date) => {
    // const formatdate = moment(date).format("DD/MM/YY");
    setselectTodate(date);
    setCurrentPage(0);
    handleFilterChange({
      status: selectedStatus,
      fromTime: fromTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: date,
      // currentPage: currentPage,
    });
  };
  const handleFromTimeChange = (event) => {
    const selectedTime = event.target.value;
    setFromtimevalue(selectedTime);
    console.log(selectedTime);
    // const formattedTime = selectedTime.toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    const formattedTime = moment(selectedTime, ["h:mm A"]).format("HH:mm");
    setFromTime(formattedTime);
    handleFilterChange({
      status: selectedStatus,
      fromTime: formattedTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      // currentPage: currentPage,
    });
  };
  const handleToTimeChange = (event) => {
    const selectedTime = event.target.value;
    setTotimevalue(selectedTime);
    // const formattedTime = selectedTime.toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    const formattedTime = moment(selectedTime, ["h:mm A"]).format("HH:mm");
    setToTime(formattedTime);
    handleFilterChange({
      status: selectedStatus,
      fromTime: fromTime,
      toTime: formattedTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      // currentPage: currentPage,
    });
  };

  const handleStatus = async (event) => {
    setSelectedStatus(event.target.value);
    console.log(event.target.value);
    await handleFilterChange({
      status: event.target.value,
      fromTime: fromTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      // currentPage: currentPage,
    });
  };

  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);

  const handleFilterChange = async ({
    selectfromdate,
    selecttodate,
    fromTime,
    toTime,
    status,
  }) => {
    try {
      // const statusValue = document.getElementById("status").value;
      // const fromDate = fromDateRef.current.value;
      // const toDate = toDateRef.current.value;
      // const fromTime = document.getElementsByName("FromTime")[0].value;
      // const toTime = document.getElementsByName("ToTime")[0].value;

      // const isFiltersEmpty = !statusValue && !fromDate && !toDate && !fromTime && !toTime;
      const fromDate = selectfromdate
        ? moment(selectfromdate).format("DD/MM/YYYY")
        : "";
      const toDate =
        selecttodate && moment(selecttodate).isValid()
          ? moment(selecttodate).format("DD/MM/YYYY")
          : "";
      const filters = {
        status: status,
        fromDate,
        toDate,
        fromTime: fromTime ? fromTime : "",
        toTime: toTime ? toTime : "",
        // page: isFiltersEmpty ? currentPage.toString() : "0"
      };
      // console.log(filters);
      const response = await PreviousMeeting(filters);
      console.log("MeetingList--data", response.parameters.data);
      // setMeetingData(response.parameters.data);
      // setTotalCount(response.parameters.total);
      // setPerPageCount(response.parameters.to);
      // setStartCount(response.parameters.from);
      setPreviousVideoData(response.parameters.data);
      setLoader(false);
    } catch (error) {
      console.error("Error occurred during filtering meetings:", error);
      setLoader(false);
    }
  };

  //   useEffect(() => {
  //     // PreviousVideo();
  //     handleFilterChange();
  //   }, []);

  const [first, setFirst] = useState(true);
  const handlePage = async () => {
    if (first) {
      try {
        const filters = {
          status: "",
          fromDate: "",
          toDate: "",
          fromTime: "",
          toTime: "",
        };

        const response = await PreviousMeeting(filters);
       
        console.log("MeetingList--data", response.parameters.data);
        // setVideoData(response.parameters.data);
        // setMeetingData(response.parameters.data);
        // setTotalCount(response.parameters.total);
        // setPerPageCount(response.parameters.to);
        // setStartCount(response.parameters.from);
        setPreviousVideoData(response.parameters.data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        setFirst(true);
        console.error("Error occurred during filtering meetings:", error);
      }
    }
  };
  useEffect(() => {
    // PreviousVideo();
    handlePage();
  }, []);
  const [count, setcount] = useState(0);
  // console.log(count);
  useEffect(() => {
    setTimeout(() => {
      handleFilterChange({
        status: selectedStatus,
        fromTime: fromTime,
        toTime: toTime,
        selectfromdate: selectfromdate,
        selecttodate: selecttodate,
        // currentPage: currentPage,
      });
      setcount(count + 1);
    }, 30000);
  }, [count]);

  const handleResetFilters = () => {
    // fromDateRef.current.value = "";
    // toDateRef.current.value = "";
    // document.getElementById("status").value = "";
    // document.getElementsByName("FromTime")[0].value = "00:00"; // Set FromTime to 00:00
    // document.getElementsByName("ToTime")[0].value = "00:00";
    // defaultTime.setHours(0);
    // defaultTime.setMinutes(0);
    // handleFilterChange();
    document.getElementById("status").value = "";

    setSelectedStatus("");
    setSelectedDate(null);
    setselectTodate(null);
    setFromTime(null);
    setToTime(null);
    setCurrentPage(0);
    handleFilterChange({
      status: "",
      fromTime: null,
      toTime: null,
      selectfromdate: null,
      selecttodate: null,
      // currentPage: "0",
    });
    setTotimevalue(null);
    setFromtimevalue(null);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="mob_fil_align">
            <h3 className="page_title">Previous Meetings</h3>
            <div
              className="mobile_filter_icon mob_fills_icon z-index-3"
              onClick={toggleVisibilityMenu}
            >
              <img src={filter_icon} alt="filter" />
            </div>
          </div>
        </div>
      </div>

      <div className="row desktop_filter">
        <div className="col-12">
          <form>
            <div className={`filter_container ${isOpen ? "open" : ""}`}>
              <div
                className="filter_icon filter_col sm_flex-end hide_mobile"
                onClick={toggleVisibilityMenu}
              >
                <img src={filter_icon} alt="filter" className="" />
                {/* <IoClose className="show_mobile filter_close" /> */}
              </div>

              <div
                className="close_sidemenu_mobile"
                onClick={toggleVisibilityMenu}
              >
                {isOpen && (
                  <IoCloseCircle
                    className="close_icon_mobile_response"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  />
                )}
              </div>
              <div className="fw-bold filter_title filter_col mobile-mt-3 d-flex align-items-center justify-content-center">
                Filter By
              </div>
              <div className="status_filter filter_col">
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  onChange={handleStatus}
                >
                  <option value="">Status</option>
                  <option value="UPCOMING">Upcoming</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="LIVE">Live</option>
                </select>
              </div>
              <div className="from_date-filter filter_col date_picker">
                {/* <input
                  ref={fromDateRef}
                  type="text"
                  className="form-control border-0"
                  placeholder="From Date"
                  name="FromDate"
                  onChange={handleFromDateChange}
                /> */}
                {/* <DatePicker
                  className="form-control border-0"
                  selected={selectfromdate}
                  onChange={handleDateChange}
                  dateFormat="dd/MM/YYYY"
                  placeholderText="From Date"
                /> */}
                {/* <DatePicker
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                      >
                        {"<"}
                      </button>
                      <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                      >
                        {">"}
                      </button>
                    </div>
                  )}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                /> */}
                <Datepickercomponent
                  selecteddate={selectfromdate}
                  handleDate={handleDateChange}
                  placeholderText="From Date"
                />
              </div>
              <div className="to_date-filter filter_col date_picker">
                {/* <input
                  ref={toDateRef}
                  type="text"
                  className="form-control border-0"
                  placeholder="To Date"
                  name="ToDate"
                  onChange={handleToDateChange}
                /> */}
                {/* <DatePicker
                  className="form-control border-0 "
                  selected={selecttodate}
                  onChange={handleTodate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="To Date"
                /> */}
                <Datepickercomponent
                  selecteddate={selecttodate}
                  handleDate={handleTodate}
                  placeholderText="To Date"
                />
              </div>
              <div className="from_time-filter filter_col p-0 down_arrow-none">
                {/* <input type="time" name='FromTime' className="form-control" onChange={handleFilterChange} /> */}
                <TimePicker
                  title={fromTime}
                  value={fromtimevalue}
                  format="HH:mm"
                  placeholder="From Time"
                  name="FromTime"
                  className="form-control border-0 h-100 w-100"
                  onChange={handleFromTimeChange}
                />
              </div>
              <div className="to_time-filter filter_col p-0 down_arrow-none">
                {/* <input type="time" name='ToTime' className="form-control" onChange={handleFilterChange} /> */}
                <TimePicker
                  title={toTime}
                  value={totimevalue}
                  format="HH:mm"
                  placeholder="To Time"
                  name="ToTime"
                  className="form-control border-0 h-100 w-100"
                  onChange={handleToTimeChange}
                />
              </div>
              <div
                className="reset_filter filter_col d-flex align-items-center justify-content-center"
                onClick={handleResetFilters}
              >
                <img src={reload_icon} alt="reset" className="me-2" /> Reset
                Filter
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="previous_video-list">

        {Loader ? < SkeletonLoader /> : <>   <Swiper
          slidesPerView={6}
          spaceBetween={30}
          navigation={true}
          modules={[Pagination, Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            769: {
              slidesPerView: 6,
            },
          }}
          className="swiper_card"
        >
          {previousVideoData.length > 0 ? (
            previousVideoData &&
            Array.isArray(previousVideoData) &&
            previousVideoData.map((data, index) => (
              <SwiperSlide key={index}>
                <Link
                  to={`/user/livevideos/meeting-detail/${data.id}`}
                  className="text-decoration-none"
                >
                  <div className="video_blog-card p-2">
                    <div className="video_banner mb-2 position-relative">
                      {data.cover_link && <img
                        src={data.cover_link}
                        alt={data.video_title}
                        fallbackImage={videoImg}
                                  onError={(e) => {
                                    e.target.src = videoImg; 
                                  }}
                      />}
                      {!data.cover_link && <img
                        src={videoImg}
                        alt={"Maintenance "}
                        fallbackImage={videoImg}
                                  onError={(e) => {
                                    e.target.src = videoImg; 
                                  }}
                      />}
                      <div className="previous_video-play">
                        <span>
                          <FaPlay />
                        </span>
                      </div>
                    </div>
                    <div className="video_description">
                      <div className="video_title">{data.video_title}</div>
                      {/* <div className="video_duration">2 Hours 15 Mins</div> */}
                      <div className="">
                        <VideoDuration
                          from_time={data.from_time ? data.from_time : "00:00"}
                          to_time={data.to_time ? data.to_time : "00:00"}
                        />
                        <div className="video_duration">
                          (
                          {data.from_time
                            ? moment(data.from_time, "HH:mm:ss").format("HH:mm")
                            : "00:00"}{" "}
                          -
                          {data.to_time
                            ? moment(data.to_time, "HH:mm:ss").format("HH:mm")
                            : "00:00"}
                          )
                        </div>
                        <div className="video_date pt-2">
                          Date : {data.video_date}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))
          ) : (
            <p><b>There is no previous meetings</b></p>
          )}
        </Swiper></>}
      </div>
    </>
  );
}

export default PreviousSessionMeetings;
