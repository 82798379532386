import React, { useState, useEffect } from "react";
import "./sidemenu.css";
import getCookie from "../Api/get-cookies";
import logo from "../../assets/images/parliament-logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink, useNavigate } from "react-router-dom";
import { VscDashboard } from "react-icons/vsc";
import { VscCommentDiscussion } from "react-icons/vsc";
import { VscHeart } from "react-icons/vsc";
import { VscExtensions } from "react-icons/vsc";
import { VscGear } from "react-icons/vsc";
import { AiOutlinePoweroff } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import { LiaTimesSolid } from "react-icons/lia";
import { IoCloseCircle } from "react-icons/io5";
import { HiOutlineStatusOnline } from "react-icons/hi";
import { GoReport } from "react-icons/go";
import { FaRegHeart } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { jwtDecode } from "jwt-decode";
import { LuListVideo } from "react-icons/lu";
import press_img from "../../assets/images/presslogo.png";
// import Api from '../Api/Api';
function Sidemenu({ setlogged_in, onLogout }) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {
    document.body.style.overflow = isSidebarOpen ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSidebarOpen]);
  const user_role = localStorage.getItem("user_role");
  // const { SignOut } = Api();
  const moment = require("moment");
  const navigate = useNavigate();
  // const handleLogout = () => {
  //     setlogged_in(false);
  //     onLogout();
  //     localStorage.removeItem('auth_token');
  //     // localStorage.setItem('logged_in', JSON.stringify(false));
  // }

  const UserRole = localStorage.getItem("user_role");

  const handleLogout = () => {
    localStorage.removeItem("auth_token");
    setlogged_in(false);
    // document.cookie =
    //   "auth_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // localStorage.removeItem("auth_token"); // Remove the auth token from localStorage
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_role");
    localStorage.removeItem("email_id");
    localStorage.removeItem("user_name");
    localStorage.setItem("logged_in", "false");
    localStorage.removeItem("logged_in");
    sessionStorage.removeItem("sortOrder");
    sessionStorage.removeItem("prev-sortOrder");
    document.body.classList.remove("sidebar_open");
    if (user_role === "ADMIN") {
      navigate("/");
    } else {
      navigate("/");
    }
  };

  const closemobiletogglebar = () => {
    setSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      document.body.classList.add("sidebar_open");
    } else {
      document.body.classList.remove("sidebar_open");
    }
  };

  // //auto logout
  // useEffect(() => {
  //   const cookies = document.cookie.split(";");
  //   const cookiestoken = getCookie("auth_token");

  //   for (let cookie of cookies) {
  //     const [name, value] = cookie.split("=").map((c) => c.trim());
  //    //  console.log({ name, value });
  //    //  console.log(
  //       name == "auth_token" &&
  //         (value === null || value === undefined || value === "null")
  //     );
  //     if (
  //       name === "auth_token" &&
  //       (value === null || value === undefined || value === "null")
  //     ) {
  //       // Logout user if token is expired or missing
  //       handleLogout();
  //       break;
  //     }
  //   }
  //   var now = new Date();
  //   var expireTime = now.getTime() + 1 * 60 * 60 * 1000; // Adding 1 hour
  //   now.setTime(expireTime);
  //   document.cookie = "cookie=ok;expires=" + now.toUTCString() + ";path=/";

  //   const expirationDate = new Date();
  //   expirationDate.setTime(expirationDate.getTime() + 1 * 60 * 60 * 1000);
  //   document.cookie = `auth_token=${cookiestoken}; expires=${now.toUTCString()}; path=/`;
  // }, [navigate]);
  // const cookiestoken = getCookie("auth_token");
  const cookiestoken = localStorage.getItem("auth_token");
  useEffect(() => {
    // Check if token exists
    //  console.log(cookiestoken);
    if (cookiestoken) {
      // Decode the token
      const decodedToken = jwtDecode(cookiestoken);
      // Get the expiration time in milliseconds
      const expirationTime = decodedToken.exp * 1000;
      // Get the current time
      const currentTime = Date.now();
      const expirationDate = new Date(expirationTime);
      ////  console.log(expirationDate);
      //  console.log(expirationDate);
      const nowdate = moment(currentTime);
      const nowtime = nowdate.format("HH:mm:ss");
      const date = moment(expirationDate);
      const time = date.format("HH:mm:ss");
      //  console.log(currentTime);
      //  console.log(expirationTime);
      //  console.log(currentTime > expirationTime);
      // Check if the token is expired
      if (currentTime > expirationTime) {
        // Token has expired, logout user
        handleLogout();
      }
    }
  }, [navigate]);

  const handlesidenav = () => {
    //  console.log(window.innerWidth > 740);
    if (window.innerWidth < 800) {
      ////  console.log("wrk");
      closemobiletogglebar();
    }
  };

  return (
    <div>
      <div className="main_sidemenu">
        <div className="sidemenu_logo">
          <div className="pos-rel">
            <img src={logo} alt="logo" />
            <div className="close_sidemenu" onClick={closemobiletogglebar}>
              {isSidebarOpen ? <IoCloseCircle /> : <FiMenu />}
            </div>
          </div>
        </div>
        <div className="list_main_menu">
          <ul className="main_main_ul_list">
            {UserRole == "ADMIN" && (
              <>
                <li>
                  <NavLink
                    to="/admin/dashboard"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <VscDashboard />
                      </div>
                      <div className="main_menu_name">Dashboard</div>
                    </div>
                  </NavLink>
                </li>

                <li className="">
                  <NavLink
                    to="/admin/meetings"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <VscExtensions />
                      </div>
                      {/* <div className="main_menu_name">Previous Meetings</div> */}
                      <div className="main_menu_name">Meeting Hub</div>
                    </div>
                  </NavLink>
                </li>
              </>
            )}

            {UserRole == "USER" && (
              <>
                {/* <li className="">
                                    <NavLink to="/user/meetings" activeClassName="active" className="page_list">
                                        <div className="inner_page_list">
                                            <div className="main_menu_icon"><VscExtensions /></div>
                                            <div className="main_menu_name">
                                                Meeting List
                                            </div>
                                        </div>
                                    </NavLink>
                                </li> */}
                <li className="">
                  <NavLink
                    to="/user/livevideos"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <HiOutlineStatusOnline />
                      </div>
                      <div className="main_menu_name">Live Meetings</div>
                    </div>
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    to="/user/videos"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <VscCommentDiscussion />
                      </div>
                      {/* <div className="main_menu_name">Previous Meetings</div> */}
                      <div className="main_menu_name">Meeting Hub</div>
                    </div>
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    to="/user/likedvideos"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <AiOutlineLike />
                      </div>
                      {/* <div className="main_menu_name">Liked Meetings</div> */}
                      <div className="main_menu_name">Favorite Meetings</div>
                    </div>
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    to="/user/press-release"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <img
                          src={press_img}
                          style={{ width: "20px", height: "20px" }}
                        />
                      </div>
                      {/* <div className="main_menu_name">Press Releases</div> */}
                      <div className="main_menu_name">News Updates</div>
                    </div>
                  </NavLink>
                </li>
              </>
            )}

            {UserRole == "ADMIN" && (
              <>
                <li className="">
                  <NavLink
                    to="/admin/likedmeetings"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <AiOutlineLike />
                      </div>
                      {/* <div className="main_menu_name">Liked Meetings</div> */}
                      <div className="main_menu_name">Favorite Meetings</div>
                    </div>
                  </NavLink>
                </li>
                {/* <li className="">
                  <NavLink
                    to="/admin/all-meetings"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <LuListVideo />
                      </div>
                      <div className="main_menu_name">All Meetings</div>
                    </div>
                  </NavLink>
                </li> */}
                <li className="d-none">
                  <NavLink
                    to="/admin/subcribedmeeeting"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <FaRegHeart />
                      </div>
                      <div className="main_menu_name">Subscribed </div>
                    </div>
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    to="/admin/reports"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <GoReport />
                      </div>
                      <div className="main_menu_name">Reports</div>
                    </div>
                  </NavLink>
                </li>
                <li className="">
                  <NavLink
                    to="/admin/settings"
                    activeClassName="active"
                    className="page_list"
                    onClick={handlesidenav}
                  >
                    <div className="inner_page_list">
                      <div className="main_menu_icon">
                        <VscGear />
                      </div>
                      <div className="main_menu_name">Settings</div>
                    </div>
                  </NavLink>
                </li>
              </>
            )}

            <li className="">
              <div
                className="page_list"
                data-bs-toggle="modal"
                data-bs-target="#LogoutModal"
              >
                <div className="inner_page_list">
                  <div className="main_menu_icon text-danger">
                    <AiOutlinePoweroff />
                  </div>
                  <div className="main_menu_name text-danger">Logout</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="modal fade logout_modal"
        id="LogoutModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="LogoutModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content py-4">
            <div class="modal-body">
              <div className="row justify-content-center">
                <div className="col-12 col-sm-8">
                  <h2 className="text-center m-0 p-0">
                    You have logged out of your account, are you sure?
                  </h2>
                </div>
              </div>
            </div>
            <div class="modal-footer justify-content-center border-0 p-0">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                No
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleLogout}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidemenu;
